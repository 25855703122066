<template>
	<div class="blueprint" v-title="'企业蓝图——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
        <div class="box1">
		    <img class="bran" src="../image/about/blue_banner.jpg" alt="" />
        </div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/about/blue_banner.jpg"
				alt=""
			/>
		</div>
        <div class="content">
			<div class="nav-list">
				<AboutNav></AboutNav>
			</div>
			<div class="box f-y-s-c">
				<div class="part1">
					<div class="header">
						<span class="text1">使命&nbsp;·&nbsp;创享崭新生活</span>
						<div class="cus-line"></div>
					</div>
					<span class="text2"
						>远景：千家美在每座城带动每个人在一起从心出发创享温馨的家开启崭新生活</span
					>
				</div>
				<div class="part2">
					<div class="header">
						<span class="text1">我们的承诺</span>
						<div class="cus-line"></div>
					</div>
					<div class="list f-x-b-c">
						<div class="list-item f-y-s-s">
							<img
								class="img"
								src="../image/about/blue_part2_1.jpg"
								alt=""
							/>
							<span class="text2">对企业</span>
							<span class="text3"
								>我们承诺践行企业蓝图，链接人生召唤和企业使命，坚守企业核心价值，用行动荣耀承诺，实现企业
								远景。
							</span>
						</div>
						<div class="list-item f-y-s-s">
							<img
								class="img"
								src="../image/about/blue_part2_2.jpg"
								alt=""
							/>
							<span class="text2">对客户</span>
							<span class="text3"
								>我们承诺用心聆听客户的需求，提供超预期的产品和服务。我们100%负责任地解决您所有的困惑和问题，给您一个崭新的家装体验，用心为您打造一个温馨的家。
							</span>
						</div>
						<div class="list-item f-y-s-s">
							<img
								class="img"
								src="../image/about/blue_part2_3.jpg"
								alt=""
							/>
							<span class="text2">对合作</span>
							<span class="text3"
								>我们承诺创造相互信赖、平等、共赢的战略伙伴关系，共同发展，彼此成就，实现崭新未来。</span
							>
						</div>
						<div class="list-item f-y-s-s">
							<img
								class="img"
								src="../image/about/blue_part2_4.jpg"
								alt=""
							/>
							<span class="text2">对伙伴</span>
							<span class="text3"
								>我们是彼此的后盾，任何时候遇到任何困难，相互赋予力量，像家人一样相互帮助，共同拼搏、成长，实现崭新未来。</span
							>
						</div>
					</div>
				</div>
				<div class="part3">
					<div class="header">
						<span class="text1">核心价值</span>
						<div class="cus-line"></div>
					</div>
					<span class="text2">爱：爱生活，爱千家美，以千家美人为荣，以贡献千家美为荣</span>
					<span class="text2">真：真实，讲真话，真干</span>
					<span class="text2">责：负责人，这就是我的事，直到解决为止</span>
					<span class="text2">荣：言行一致，兑现承诺</span>
					<span class="text2">赢：打赢实现蓝图的每一场比赛</span>
				</div>
                <div class="part4">
					<div class="header">
						<span class="text1">创新拼搏的精神</span>
						<div class="cus-line"></div>
					</div>
					<span class="text2">勇气：面对未来，无惧挑战</span>
					<span class="text2">创新：持续创造，不断引领</span>
					<span class="text2">拼搏：全力以赴，创造奇迹</span>
					<span class="text2">坚持：永不放弃，直到胜利</span>
                    <div class="text-box f-x-s-s">
                        <div class="title">产品：</div>
                        <div class="label">我们承诺不断创新。用专业的设计、精益求精的工艺，匠心打造高品质的产品</div>
                    </div>
                    <div class="text-box f-x-s-s">
                        <div class="title">环境：</div>
                        <div class="label">我们承诺构建绿色供应链，使用健康安全的材料，开启低碳环保生活，创建绿色环保家园，造福世界</div>
                    </div>
                    <div class="text-box f-x-s-s">
                        <span class="title">股东：</span>
                        <p class="label">我们承诺打造有竞争力的企业，让股东以投资沪尚茗居为荣</p>
                    </div>
                    <div class="text-box f-x-s-s">
                        <span class="title">社会：</span>
                        <p class="label">我们承诺做从心护法运作的行业典范，影响带动人们传递爱，推动中国社会的进步，让世界更精彩。</p>
                    </div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import AboutNav from '../components/AboutNav.vue'
export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
	},
	data() {
		return {
			addressList: [
				{
					address: '上海市奉贤区南奉公路7500号苏宁生活广场3F',
					title: '奉贤店',
					position: {
						lng: '121.495517',
						lat: '30.924118',
					},
					url: require('../image/about/address1.jpg'),
				},
				{
					address: '上海市静安区沪太路855号 ',
					title: '静安店',
					position: {
						lng: '121.44574',
						lat: '31.275387',
					},
					url: require('../image/about/address2.jpg'),
				},
				{
					address: '河南省郑州市金水区花园路8号正道花园百货3F~5F',
					title: '郑州店',
					position: {
						lng: '113.689338',
						lat: '34.774986',
					},
					url: require('../image/about/address3.jpg'),
				},
			],
		}
	},
	methods: {},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .blueprint {
        overflow-x: hidden;
		.box1{
            display: none;
        }
        .box-mob {
			width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		
		.content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
            flex-flow: column;
            .nav-list{
                width: 100%;
            }
			.box {
				width: 100%;
				background: #ffffff;
				border-radius: 9px;
				.part1 {
					width: 100%;
					padding: 0 10px;
					margin-top: 20px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					align-items: center;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 20px;
							color: #241934;
						}
						.cus-line {
							width: 180px;
							height: 3px;
							background: #753cbe;
							margin-top: 5px;
						}
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 13px;
						color: #241934;
						line-height: 22px;
					}
				}
				.part2 {
					width: 100%;
					margin-top: 50px;
					padding: 0 12px;
					display: flex;
					flex-flow: column;
					align-items: center;
					box-sizing: border-box;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 20px;
							color: #241934;
						}
						.cus-line {
							width: 100px;
							height: 3px;
							background: #753cbe;
							margin-top: 5px;
						}
					}
					.list {
						margin-top: 10px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						width: 100%;
						.list-item {
							width: 100%;
							height: 250px;
							box-sizing: border-box;
							.img {
								width: 100%;
								height: 170px;
                                object-fit: cover;
							}
							.text2 {
								
								font-weight: bold;
								font-size: 14px;
								color: #241934;
								margin-top: 5px;
							}
							.text3 {
								
								font-weight: 400;
								font-size: 10px;
								color: #241934;
								line-height: 15px;
								// padding-right: 10px;
								box-sizing: border-box;
							}
						}
					}
				}
				.part3 {
					width: 100%;
					padding: 0 10px;
					margin-top: 50px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					.header {
                        width: 100%;
                        // text-align: center;
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        margin-bottom: 5px;
						.text1 {
							
							font-weight: bold;
							font-size: 20px;
							color: #241934;
						}
						.cus-line {
							width: 85px;
                            height: 3px;
                            background: #753CBE;
							margin-top: 5px;
						}
					}
					.text2 {
						
						font-weight: 400;
						font-size: 13px;
						color: #241934;
						line-height: 25px;
					}
				}
                .part4 {
					width: 100%;
					padding: 0 10px;
					margin-top: 50px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
                    margin-bottom: 50px;
					.header {
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 5px;
						.text1 {
							
							font-weight: bold;
							font-size: 20px;
							color: #241934;
						}
						.cus-line {
							width: 140px;
                            height: 3px;
                            background: #753CBE;
							margin-top: 5px;
						}
					}
					.text2 {
						
						font-weight: 400;
						font-size: 13px;
						color: #241934;
						line-height: 25px;
					}
                    .text-box{
                        .title{
                            width: 40px;
                            flex-shrink: 0;
                            // width: 130px;
                            
                            font-weight: 400;
                            font-size: 13px;
                            color: #241934;
                            line-height: 20px;
                        }
                        .label{
                            
                            font-weight: 400;
                            font-size: 13px;
                            color: #241934;
                            line-height:20px;
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
				}
			}
		}
	}
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.blueprint {
        .box-mob{
            display: none;
        }
		.box1{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 60px 2% 180px 2%;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.box {
                flex: 1;
				// width: 1062px;
				background: #ffffff;
				border-radius: 18px;
				margin-left: 50px;
                // padding-bottom: ;

				.part1 {
					width: 100%;
					padding: 0 42px;
					margin-top: 42px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 340px;
							height: 6px;
							background: #753cbe;
							margin-top: 15px;
						}
					}
					.text2 {
						margin-top: 19px;
						
						font-weight: 400;
						font-size: 32px;
						color: #241934;
						line-height: 48px;
					}
				}
				.part2 {
					width: 100%;
					margin-top: 130px;
					padding: 0 42px;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					box-sizing: border-box;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 200px;
							height: 6px;
							background: #753cbe;
							margin-top: 15px;
						}
					}
					.list {
						margin-top: 25px;
						// display: flex;
						// flex-wrap: wrap;
						// justify-content: space-between;
                        display: grid;
                        grid-template-columns: repeat(2,1fr);
                        gap: 30px;
						width: 100%;
						.list-item {
							// width: 480px;
							height: 520px;
                            width: 100%;
							box-sizing: border-box;

							.img {
								width: 100%;
								height: 320px;
							}
							.text2 {
								
								font-weight: bold;
								font-size: 32px;
								color: #241934;
								margin-top: 19px;
							}
							.text3 {
								margin-top: 20px;
								
								font-weight: 400;
								font-size: 20px;
								color: #241934;
								line-height: 30px;
								box-sizing: border-box;
							}
						}
					}
				}
				.part3 {
					width: 100%;
					padding: 0 42px;
					margin-top: 130px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 160px;
                            height: 6px;
                            background: #753CBE;
							margin-top: 15px;
						}
					}
					.text2 {
						
						font-weight: 400;
						font-size: 32px;
						color: #241934;
						line-height: 60px;
					}
				}
                .part4 {
					width: 100%;
					padding: 0 42px;
					margin-top: 130px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
                    margin-bottom: 100px;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 280px;
                            height: 6px;
                            background: #753CBE;
							margin-top: 15px;
						}
					}
					.text2 {
						
						font-weight: 400;
						font-size: 32px;
						color: #241934;
						line-height: 60px;
					}
                    .text-box{
                        .title{
                            width: 98px;
                            flex-shrink: 0;
                            // width: 130px;
                            
                            font-weight: 400;
                            font-size: 32px;
                            color: #241934;
                            line-height: 50px;
                        }
                        .label{
                            
                            font-weight: 400;
                            font-size: 32px;
                            color: #241934;
                            line-height: 50px;
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
				}
			}
		}
	}
}
@media screen and (min-width: 1360px) {
	.blueprint {
        .box-mob{
            display: none;
        }
		.box1{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 60px 60px 180px 60px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.box {
				width: 1062px;
				background: #ffffff;
				border-radius: 18px;
				margin-left: 50px;
                // padding-bottom: ;

				.part1 {
					width: 100%;
					padding: 0 42px;
					margin-top: 42px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 340px;
							height: 6px;
							background: #753cbe;
							margin-top: 15px;
						}
					}
					.text2 {
						margin-top: 19px;
						
						font-weight: 400;
						font-size: 32px;
						color: #241934;
						line-height: 48px;
					}
				}
				.part2 {
					width: 100%;
					margin-top: 130px;
					padding: 0 42px;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					box-sizing: border-box;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 200px;
							height: 6px;
							background: #753cbe;
							margin-top: 15px;
						}
					}
					.list {
						margin-top: 25px;
						// display: flex;
						// flex-wrap: wrap;
						// justify-content: space-between;
                        display: grid;
                        grid-template-columns: repeat(2,1fr);
                        gap: 30px;
						width: 100%;
						.list-item {
							// width: 480px;
							height: 520px;
                            width: 100%;
							box-sizing: border-box;

							.img {
								width: 100%;
								height: 320px;
							}
							.text2 {
								
								font-weight: bold;
								font-size: 32px;
								color: #241934;
								margin-top: 19px;
							}
							.text3 {
								margin-top: 20px;
								
								font-weight: 400;
								font-size: 20px;
								color: #241934;
								line-height: 30px;
								box-sizing: border-box;
							}
						}
					}
				}
				.part3 {
					width: 100%;
					padding: 0 42px;
					margin-top: 130px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 160px;
                            height: 6px;
                            background: #753CBE;
							margin-top: 15px;
						}
					}
					.text2 {
						
						font-weight: 400;
						font-size: 32px;
						color: #241934;
						line-height: 60px;
					}
				}
                .part4 {
					width: 100%;
					padding: 0 42px;
					margin-top: 130px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					align-items: flex-start;
                    margin-bottom: 100px;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 280px;
                            height: 6px;
                            background: #753CBE;
							margin-top: 15px;
						}
					}
					.text2 {
						
						font-weight: 400;
						font-size: 32px;
						color: #241934;
						line-height: 60px;
					}
                    .text-box{
                        .title{
                            width: 98px;
                            flex-shrink: 0;
                            // width: 130px;
                            
                            font-weight: 400;
                            font-size: 32px;
                            color: #241934;
                            line-height: 50px;
                        }
                        .label{
                            
                            font-weight: 400;
                            font-size: 32px;
                            color: #241934;
                            line-height: 50px;
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
				}
			}
		}
	}
}
</style>
